import type { FunctionComponent, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { CommentWidgetPosition } from "scmp-app/components/tracking/google-analytics-4/types";
import { useArticleCommentLink } from "scmp-app/lib/hooks";
import { yieldToMain } from "scmp-app/lib/yield";
import type { articleCommentsTriggerArticle$key } from "scmp-app/queries/__generated__/articleCommentsTriggerArticle.graphql";

import { Article20Comment } from "./article-2.0";
import { Article20CommentsBanner } from "./article-2.0-banner";
import { ArticleCommentsBanner } from "./banner";
import { ArticleCommentsButton } from "./button";
import { ArticleCommentsIcon } from "./icon";
import type { Props as ArticleCommentsWidgetProps } from "./widget";
import { ArticleCommentsWidget } from "./widget";

export type Props = {
  className?: string;
  icon?: ReactNode;
  onClick?: () => void;
  reference: articleCommentsTriggerArticle$key;
  variant:
    | "article-2.0"
    | "article-2.0-banner"
    | "banner"
    | "button"
    | "icon"
    | "live-article-item"
    | "widgetDark"
    | "widgetLight";
};

export const ArticleCommentsTrigger: FunctionComponent<Props> = ({
  className,
  icon,
  onClick,
  reference: reference_,
  variant,
}) => {
  const { commentCount, entityId } = useFragment<articleCommentsTriggerArticle$key>(
    graphql`
      fragment articleCommentsTriggerArticle on Article {
        commentCount
        entityId
      }
    `,
    reference_,
  );

  const { toggleByUrl: toggleCommentByUrl } = useArticleCommentLink();

  const handleOpenComments = async (trackingPosition: CommentWidgetPosition) => {
    await yieldToMain({ priority: "user-blocking" });
    toggleCommentByUrl();

    await yieldToMain({ priority: "background" });
    sendGA4Tracking({
      action: "click",
      category: "comment",
      customized_parameters: {
        article_id: entityId,
        position: trackingPosition,
      },
      subcategory: "widget",
    });
    onClick?.();
  };

  switch (variant) {
    case "article-2.0":
      return (
        <Article20Comment
          className={className}
          commentCount={commentCount ?? 0}
          onClick={() => handleOpenComments("article_header")}
        />
      );
    case "article-2.0-banner":
      return (
        <Article20CommentsBanner
          className={className}
          commentCount={commentCount ?? 0}
          onClick={() => handleOpenComments("article_bottom")}
        />
      );
    case "banner":
      return (
        <ArticleCommentsBanner
          className={className}
          commentCount={commentCount ?? 0}
          onClick={() => handleOpenComments("article_bottom")}
        />
      );
    case "button":
      return (
        <ArticleCommentsButton
          className={className}
          commentCount={commentCount ?? 0}
          icon={icon}
          onClick={() => handleOpenComments("adhesive")}
        />
      );
    case "icon":
      return (
        <ArticleCommentsIcon
          className={className}
          onClick={() => handleOpenComments("article_bottom")}
        />
      );
    case "live-article-item":
      return (
        <ArticleCommentsIcon
          className={className}
          onClick={() => {
            toggleCommentByUrl();
            onClick?.();
          }}
        />
      );
    case "widgetDark":
    case "widgetLight":
      const widgetVariant: ArticleCommentsWidgetProps["variant"] =
        variant === "widgetDark" ? "dark" : "light";
      return (
        <ArticleCommentsWidget
          className={className}
          commentCount={commentCount ?? 0}
          onClick={() => handleOpenComments("article_side")}
          variant={widgetVariant}
        />
      );
    default:
      return null;
  }
};
